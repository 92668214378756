import VueJwtDecode from "vue-jwt-decode";

export default {
    name: 'UserRedirect',
    render (h) {
        const div = h('div')
        console.log(div, {h})
    },

    beforeMount () {

        const { query: { token, from, email, name } } = this.$route
        console.log(this.$route)
        if (token) {
            let decodedToken = VueJwtDecode.decode(token)
            sessionStorage.setItem('userData', JSON.stringify(decodedToken))
            sessionStorage.setItem('userToken', token)
            let newEmail;
            let newName
            if(email) newEmail = email.trim()
            if(name) newName = name.trim()
            sessionStorage.setItem('learnerRoute', from)
            // just tries to wait for the token to sit in, hence the timeout
            let updateQuery = {...this.$route.query}
            delete updateQuery.token
            updateQuery.email = newEmail
            updateQuery.name = newName
            setTimeout(() => {
                this.$router.push({
                    name: updateQuery.to,
                    query: {...updateQuery},
                })
            }, 250)

        }
    }
}
